/* Home Styling */
.Home {
  position: relative;
  color: black;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: 'Bubblegum';
}

/* Scrolling Text Styling */
.scrollingText {
  position: absolute;
  white-space: nowrap;
  font-size: 10vh;
  color: white;
  top: 15vh;
}

/* Animations for Home Content */
.HomeContentContainer,
.HomeContentDescription,
.HomeContentImageContainer {
  opacity: 0; /* Hide initially to avoid glitch */
  transform: translateY(20px); /* Slide effect */
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards; /* Fades in the element */
}

.pop-in {
  animation: popIn 0.8s ease-out forwards; /* Pops the element */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Home Content Styling */
.HomeContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10vw;
}

.HomeContentDescription {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.HomeContentImage {
  max-height: 88vh; /* Adjust this value to fit the image inside the viewport */
  max-width: 100%;
  object-fit: contain; 
}

.HomeContentImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Title and Text Styling */
.titleHome {
  font-size: 7vw;
}

.HomeContentTextText {
  font-size: 2.7vw;
  padding-left: 0.7vw;
}

.HomeContentTextTextItalic {
  padding-left: 1vw;
  font-size: 1.3vw;
  font-style: italic;
}

/* Button Styling */
.HomeContentBuyButtonLink {
  text-align: center;
  background-color: rgb(155, 0, 0); /* Default red background */
  color: white; /* White text color */
  padding: 1.6rem;
  border: 0.3vw solid rgb(0, 0, 0); /* Red border */
  border-radius: 0.2rem; /* Rounded corners */
  font-size: 5vw; /* Button text size */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.5s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-decoration: none; /* Remove underline */
}

.HomeContentBuyButtonLink:hover {
  background-color: rgb(54, 186, 247); /* Blue background on hover */
  transform: scale(1.05); /* Slight scale-up effect */
  text-decoration: none; /* Remove underline */
}

.triggerButtonContainer {
  padding: 1.5vw;
  font-size: 3rem;
  background-color: #007bff;
  color: black;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  border: 0.3vw solid rgb(0, 0, 0); /* Red border */
  border-radius: 0.2rem; /* Rounded corners */
  font-weight: bold; /* Bold text */
  transition: background-color 0.5s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.triggerButtonContainer:hover {
  background-color: rgb(54, 186, 247);
  transform: scale(1.05); /* Slight scale-up effect */
}

.triggerButtonSpeakerIcon {
  width: 3.8vw;
}

/* Top Links Animations and Styling */
.TopLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  font-size: 6vh;
  font-weight: bolder;
  padding-top: 4vh;
  opacity: 0; /* Start hidden */
  animation: fadeInLinks 1s ease-out forwards; /* Fade-in effect for the container */
}

.TopLinkContainer {
  text-decoration: none;
  color: rgb(155, 0, 0);
  display: inline-block;
  text-shadow: 
    -2px -2px 0 #000, /* Top left shadow */
    2px -2px 0 #000, /* Top right shadow */
    -2px 2px 0 #000, /* Bottom left shadow */
    2px 2px 0 #000; /* Bottom right shadow */
  transition: transform 0.3s ease-out, text-shadow 0.3s ease-out;
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly down */
  animation: slideIn 1s ease-out forwards;
}

.TopLinkContainer:nth-child(1) {
  animation-delay: 0.2s; /* Staggered animation for first link */
}

.TopLinkContainer:nth-child(2) {
  animation-delay: 0.4s; /* Staggered animation for second link */
}

.TopLinkContainer:nth-child(3) {
  animation-delay: 0.6s; /* Staggered animation for third link */
}

.rotate-text {
  display: inline-block;
  transition: transform 0.3s ease-out, color 0.3s ease-out, text-shadow 0.3s ease-out;
  transform-style: preserve-3d;
}

/* Keyframe for fade-in effect */
@keyframes fadeInLinks {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframe for slide-in effect */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slide in from below */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Settle into place */
  }
}

/* --- Media Queries for Mobile --- */
@media screen and (max-width: 768px) {
  .HomeContentContainer {
    flex-direction: row;
    align-items: start;
    position: relative;
    height: 100%;
  }

  /* Position image at the bottom-right of the entire page */
  .HomeContentImageContainer {
    position: absolute;
    bottom: 9vh;
    right: 0;
  }

  .HomeContentImage {
    max-height: 70vh;
  }

  /* Adjust text and title sizes */
  .titleHome {
    font-size: 10vw; /* Larger font for mobile */
  }

  .HomeContentTextText {
    font-size: 4vw; /* Adjust the font size for mobile */
    padding-left: 0;
  }

  .HomeContentTextTextItalic {
    font-size: 3vw; /* Adjust the font size for mobile */
  }

  .HomeContentBuyButtonLink {
    font-size: 6vw; /* Make button text bigger on mobile */
    padding: 1.2rem;
  }
}
