html, body {
  min-height: 100vh; /* Ensures full viewport height but allows for content overflow */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: 'Bubblegum';
  background: linear-gradient(to bottom, rgb(0, 182, 0), #007bff);

}

#root, .App {
  min-height: 100vh; /* Keeps full height inside root and App */
}
