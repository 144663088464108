/* SoundMuseum.css */

.museum-container {
  text-align: center;
  height: 100vh;
  font-family: 'Bubblegum';
  position: relative;
  overflow: hidden;
  padding-left: 3vw;
  padding-right: 3vw;
}

.museum-title {
  padding: 5vh;
  font-size: 5vh;
  font-weight: bold;
  color: rgb(155, 0, 0);
  text-shadow: 
      -0.15rem -0.15rem 0 #000,
      0.15rem -0.15rem 0 #000,
      -0.15rem 0.15rem 0 #000,
      0.15rem 0.15rem 0 #000;
}

.sound-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5vw;
}
/* 
.suui-text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.suui-text {
  position: absolute;
  font-size: 4rem;
  color: rgb(155, 0, 0);
  font-weight: bold;
  animation: shake 0.3s infinite, fadeOut 5s forwards;
  opacity: 1;
  z-index: 3;
}

@keyframes shake {
  0% { transform: translate(2px, 2px) rotate(0deg); }
  20% { transform: translate(-4px, 4px) rotate(-2deg); }
  40% { transform: translate(-8px, -8px) rotate(2deg); }
  60% { transform: translate(4px, -4px) rotate(0deg); }
  80% { transform: translate(-2px, 2px) rotate(-2deg); }
  100% { transform: translate(2px, -2px) rotate(2deg); }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
} */

@media screen and (max-width: 768px) {
  .museum-title {
    font-size: 5rem;
    padding: 2rem;
  }
}
