/* Top Links Animations and Styling */
.TopLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  font-size: 6vh;
  font-weight: bolder;
  padding-top: 4vh;
  opacity: 0; /* Start hidden */
  visibility: hidden; /* Hidden until the animation starts */
  animation: fadeInLinks 1s ease-out forwards; /* Ensures the final state is preserved */
  animation-fill-mode: forwards; /* Preserve final state after animation */
}

/* Staggered animation for individual links */
.TopLinkContainer {
  text-decoration: none;
  color: rgb(155, 0, 0);
  display: inline-block;
  text-shadow: 
    -0.15rem -0.15rem 0 #000, /* Top left shadow */
    0.15rem -0.15rem 0 #000, /* Top right shadow */
    -0.15rem 0.15rem 0 #000, /* Bottom left shadow */
    0.15rem 0.15rem 0 #000; /* Bottom right shadow */
  transition: transform 0.3s ease-out, text-shadow 0.3s ease-out;
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly down */
  visibility: hidden; /* Hidden until the animation starts */
  animation: slideIn 0.6s ease-out forwards; /* Ensures the final state is preserved */
  animation-fill-mode: forwards; /* Preserve final state after animation */
}

.TopLinkContainer:nth-child(1) {
  animation-delay: 0.2s; /* Staggered animation for first link */
}

.TopLinkContainer:nth-child(2) {
  animation-delay: 0.4s; /* Staggered animation for second link */
}

.TopLinkContainer:nth-child(3) {
  animation-delay: 0.6s; /* Staggered animation for third link */
}

/* Rotate and hover effects for link text */
.rotate-text {
  display: inline-block;
  transition: transform 0.3s ease-out, color 0.3s ease-out, text-shadow 0.3s ease-out;
  transform-style: preserve-3d;
}

.TopLinkContainer:hover .rotate-text {
  transform: rotateX(360deg) scale(1.2); /* Rotate and scale for a dynamic hover effect */
  color: rgb(54, 186, 247);
}

.TopLinkContainer:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
}

/* Keyframe for fade-in effect */
@keyframes fadeInLinks {
  from {
    opacity: 0;
    visibility: visible; /* Make visible at the start of animation */
  }
  to {
    opacity: 1;
    visibility: visible; /* Ensure visibility is retained */
  }
}

/* Keyframe for slide-in effect */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slide in from below */
    visibility: visible; /* Make visible at the start of animation */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Settle into place */
    visibility: visible; /* Ensure visibility is retained */
  }
}

/* --- Media Query for Mobile Devices --- */
@media screen and (max-width: 768px) {
  .TopLinks {
    flex-direction: row; /* Stack links vertically */
    gap: 4rem; /* Reduce the gap between links */
    font-size: 7.25vw; /* Scale down text size */
    padding-top: 2vh; /* Adjust padding to fit better on smaller screens */
  }

  .TopLinkContainer {
    font-size: 7.25vw; /* Scale text size based on viewport width */
  }

  .rotate-text {
    font-size: 7.25vw; /* Scale down the text within each link */
  }
}
