/* SoundPlayer.css */

.sound-player-container {
  text-align: center;
  padding: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 0.2rem;
  background-color: #007bff; /* Gold meme-coin like color */
  border: 0.2rem solid rgb(0, 0, 0); /* Red-orange cartoon border */
  transition: transform 0.1s ease-in-out;
}

/* Shaking animation for the container when playing */
.sound-player-container.shaking {
  animation: bigShake 0.1s infinite; /* Faster shake */
}

.username {
  font-size: 1.4em;
  color: #000000;
  font-weight: bold;
  margin-bottom: 20px;
}

.username-link {
  color: rgb(155, 0, 0);
  text-decoration: none;
  font-weight: bold;
}

.play-button {
  font-family: Bubblegum;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: rgb(155, 0, 0);
  border: 0.2rem solid rgb(0, 0, 0); /* Red-orange cartoon border */ 
  border-radius: 0.2rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);

}

.button-playing {
  background-color: rgb(155, 0, 0); /* Playful green for "Pause" state */
  transform: scale(1.1);
}

/* Keyframes for faster, more intense shake animation */
@keyframes bigShake {
  0% { transform: translate(3px, 3px) rotate(0deg); }
  20% { transform: translate(-6px, 6px) rotate(-2deg); }
  40% { transform: translate(-12px, -12px) rotate(2deg); }
  60% { transform: translate(6px, -6px) rotate(0deg); }
  80% { transform: translate(-3px, 3px) rotate(-2deg); }
  100% { transform: translate(3px, -3px) rotate(2deg); }
}
